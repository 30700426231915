<template>
  <NavQuestion
    :heading="heading"
    :number="number"
    :subHeading="subHeading"
    :questionOptions="true"
  >
    <template v-slot:contents>
      <InputOptionSmall
        :letter="'a'"
        :option="'Age 18'"
        :image="'https://mylastwill.s3.amazonaws.com/static/img/18.png'"
        :selected="details.distribution_minor_18"
        @selected="save18"
      ></InputOptionSmall>
      <InputOptionSmall
        :letter="'b'"
        :option="'Age 21'"
        :image="'https://mylastwill.s3.amazonaws.com/static/img/21.png'"
        :selected="details.distribution_minor_21"
        @selected="save21"
      ></InputOptionSmall>
      <InputOptionSmall
        :letter="'c'"
        :option="'Age 25'"
        :image="'https://mylastwill.s3.amazonaws.com/static/img/25.png'"
        :selected="details.distribution_minor_25"
        @selected="save25"
      ></InputOptionSmall>
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
        v-bind:validated="isValidated"
        v-bind:forwardText="'Next'"
        v-bind:forwardLink="forwardTo"
        v-bind:backLink="backTo"
        v-bind:center="false"
      ></NavQuestionButtons>
    </template>
  </NavQuestion>
</template>

<script>
import NavQuestion from '@/common/ui/NavQuestion'
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'
import InputOptionSmall from '@/common/ui/InputOptionSmall'

export default {
  name: 'DistributionMinors',
  components: {
    NavQuestion,
    NavQuestionButtons,
    InputOptionSmall
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch('saveDetails')
    next()
  },
  computed: {
    partner() {
      return this.$store.getters.partner
    },
    number() {
      var number = 1
      if (this.partner) number += 1
      if (this.$store.getters.peopleDistributionPrimary)
        number += this.$store.getters.peopleDistributionPrimary.length
      if (this.$store.getters.groupsDistributionPrimary)
        number += this.$store.getters.groupsDistributionPrimary.length
      if (this.details.distribution_all_die_backup_table) number += 1
      if (
        !this.$store.getters.groupsDistributionPrimary.length &&
        this.$store.getters.peopleDistributionPrimary.length === 1 &&
        this.$store.getters.peopleDistributionPrimary[0]
          .distribution_backup_children_no
      )
        number -= 1
      return number + 2
    },
    details() {
      return this.$store.getters.details
    },
    isValidated() {
      if (
        this.details.distribution_minor_18 ||
        this.details.distribution_minor_21 ||
        this.details.distribution_minor_25
      )
        return true
      return false
    },
    peoplePrimary() {
      return this.$store.getters.peopleDistributionPrimary
    },
    groupsPrimary() {
      return this.$store.getters.groupsDistributionPrimary
    },
    charitiesPrimary() {
      return this.$store.getters.charitiesDistributionPrimary
    },
    forwardTo() {
      return '/distribution/disability_option'
    },
    backTo() {
      if (this.charitiesPrimary.length) {
        if (this.groupsPrimary && this.groupsPrimary.length) {
          return `/distribution/primary_group/${
              this.groupsPrimary[this.groupsPrimary.length - 1].group_name
          }/${this.groupsPrimary[this.groupsPrimary.length - 1].id}`
        } else if (this.peoplePrimary && this.peoplePrimary.length)
          return `/distribution/primary_person/${
              this.peoplePrimary[this.peoplePrimary.length - 1].full_name
          }/${this.peoplePrimary[this.peoplePrimary.length - 1].id}`
        return '/distribution/beneficiaries'
      }
      if (this.details.distribution_all_die_dont_care)
        return '/distribution/all_fail'
      return '/distribution/substitute_beneficiaries'
    }
  },
  data() {
    return {
      heading:
        'If a minor were to benefit under your Will, at what age should they receive their inheritance?',
      subHeading:
        ' Unless a Will states otherwise, the inheritance of a minor beneficiary will be looked ' +
        "after by trustees until the child's 18th birthday. You have the option of stipulating a " +
        'different age in your will, such as 21 or 25 if you feel this would be more appropriate.'
    }
  },
  methods: {
    save18(value) {
      this.$store.commit('details', {
        distribution_minor_18: true,
        distribution_minor_21: false,
        distribution_minor_25: false
      })
    },
    save21(value) {
      this.$store.commit('details', {
        distribution_minor_18: false,
        distribution_minor_21: true,
        distribution_minor_25: false
      })
    },
    save25(value) {
      this.$store.commit('details', {
        distribution_minor_18: false,
        distribution_minor_21: false,
        distribution_minor_25: true
      })
    }
  }
}
</script>
